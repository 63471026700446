.public-channel-stats {
  $header-size: 60px;

  &__header {
    height: $header-size;
    padding: 0 16px;
    background: $color-white;
    box-shadow: 0 1px 7px rgba($color-black, 0.1);
    border-bottom: 1px solid rgba($color-black, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__header-logo {
    width: 36px;
    height: 36px;
  }

  &__power {
    display: flex;
    align-items: center;
  }

  &__power-link {
    font-size: 10px;
    font-weight: 500;
  }

  &__power-text {
    font-size: 12px;
  }

  &__power-logo {
    max-width: 50px;
    height: auto;
    margin: 0 8px;
  }

  &__loading {
    margin-top: 100px;
  }

  &__error {
    text-align: center;
    margin-top: 100px;
  }

  &__page {
    display: flex;
    justify-content: center;
    padding: $header-size 20px 0 20px;
  }

  &__card {
    background-color: $color-white;
    max-width: 700px;
    width: 100%;
    border-radius: 4px;
    margin: 20px 0;
  }

  &__head {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  }

  &__info {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
  }

  &__subtitle {
    display: flex;
    align-items: center;

    &-text {
      font-size: 11px;
      margin-right: 8px;
    }
  }

  &__status {
    &-circle {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: rgba($color-black, 0.05);
      margin: 0 4px 0 0;

      &.-green {
        background: $color-green-seafoam;
      }

      &.-orange {
        background: $color-orange-glow;
      }

      &.-red {
        background: $color-red-candy;
      }
    }

    &-text {
      font-size: 11px;
      margin-right: 8px;
    }
  }

  &__content {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 20px;
  }

  &__item {
    flex: 1;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 10px 0;
    padding: 10px;
    display: flex;
    width: 100%;
  }

  &__links {
    flex: 1;
    width: 100%;
  }

  &__provider,
  &__total {
    flex: 1;
    padding: 8px;
  }

  &__quotas {
    flex: 1;
    padding: 8px;

    &-item {
      margin: 0 0 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
