.channel-form-external-panel {
  &__hidden {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  &__provider-zero {
    font-size: 12px;
    font-weight: 600;
    color: $color-blue-clear;
    cursor: pointer;
  }

  &__provider {
    position: relative;
    border-radius: 4px;
    border: 1px solid rgba($color-black, 0.1);
    padding: 24px;

    &-actions {
      position: absolute;
      top: 3px;
      right: 8px;
    }

    &-action {
      font-size: 12px;
      font-weight: 600;
      margin-left: 16px;
    }
  }

  &__summary {
    margin: 24px 0 0 0;

    &-title {
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 10px;
    }

    &-text {
      line-height: 1.2;
    }

    b {
      color: $color-orange-blaze;
    }
  }

  &__id {
    font-size: 11px;
    color: rgba($color-black, 0.4);
    margin-top: 24px;
  }
}
