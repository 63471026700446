.channel-links {
  &__item {
    display: flex;
    justify-content: space-between;

    &-main {
      font-size: 14px;
      max-width: 184px; // HACK: stop growing due to long links
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      font-size: 14px;
    }

    &-link {
      display: flex;
      a {
        @extend %ellipsis;
      }
    }

    &-aspect {
      color: rgba($color-black, 0.56);
    }

    &-qr {
      display: flex;
      align-items: center;
      justify-content: right;
      margin-left: 20px;
      width: 70px;
      flex: 0 0 auto; /* Don't grow, don't shrink, auto basis */

      img {
        cursor: pointer;
      }
    }
  }

  &__link {
    &-title {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    &-info {
      font-size: 11px;
      color: rgba($color-black, 0.4);
      margin-bottom: 10px;
    }

    &-link {
      display: flex;
      align-items: center;
    }

    &-status-circle {
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: 4px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 8px;

      &.-enabled {
        background: $color-green-seafoam;
      }

      &.-disabled {
        background: $color-red-candy;
      }
    }

    &-url {
      @extend %ellipsis;
      min-width: 0;
      flex: 1;
      font-size: 14px;
    }

    &-copy-btn {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 8px;
      color: rgba($color-black, 0.4);

      &:hover {
        cursor: pointer;
        color: rgba($color-black, 0.8);
      }
    }

    &-copy-btn-label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__links {
    padding: 8px;
    width: 100%;

    &-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 11px;
    }

    &-desc {
      font-size: 12px;
      color: rgba($color-black, 0.64);
      margin: 0 0 16px;
    }

    &-status {
      font-size: 12px;
      color: rgba($color-black, 0.64);
      margin: 0 0 16px;
    }

    &-label {
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 4px;
    }

    &-link {
      background: rgba($color-black, 0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      margin: 0 0 8px;

      &-status {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 7px;

        &.-enabled {
          background: $color-green-seafoam;
        }

        &.-disabled {
          background: $color-red-candy;
        }
      }

      &-url {
        flex: 1;
        @extend %ellipsis;
        min-width: 0;
        font-size: 11px;
        color: rgba($color-black, 0.64);
        line-height: 1;
      }

      &-copy {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &-copy-text {
        font-size: 10px;
        color: rgba($color-black, 0.64);
      }
    }
  }
}
