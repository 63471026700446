//
// Global settings for Glow Subscriber Portal
//

// Global breakpoints
// NOTE: using max-width as design is for desktop, no mobile designs supplied
//       ideally this should be mobile-first using min-widths
// ------------------------------------
$breakpoints: (
  'small': (
    max-width: 640px,
  ),
  'medium': (
    max-width: 1024px,
  ),
  'large': (
    max-width: 1400px,
  ),
  'xlarge': (
    max-width: 1920px,
  ) //'xxlarge',
);

$max-content-width: 1040px;

// Colours
// ------------------------------------
$color-black: #000;
$color-blue-clear: #3aa3e3;
$color-blue-slate: #3c4b5b;
$color-blue-athens-gray: #e6e8eb;
$color-green-seafoam: #2ab27b;
$color-grey-indifferent: #555459;
$color-grey-silver: #9e9ea6;
$color-grey-soft: #e8e8e8;
$color-grey-gallery: #efefef;
$color-grey-severe: #3d3c40;
$color-grey-light: #e1e1e1;
$color-orange-neon-carrot: #f93;
$color-orange-sea-buckthorn: #f78c28;
$color-orange-glow: #faa41a;
$color-orange-tango: #f47421;
$color-orange-blaze: #ff6600;
$color-purple-fuchia: #7d60c5;
$color-red-candy: #eb4d5c;
$color-white: #fff;
$color-white-alabaster: #f7f7f7;
$color-yellow-star: #fc0;
$color-dodger-blue: #2196f3;

// backgrounds
$color-portal-background: #eaeaef;

// alert
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;
$color-warning: #f0ad4e !default;
$color-danger: #d9534f !default;

// rating stars
$color-star-yellow: #f6d513;
$color-star-grey: #c8c8c8;

// moods
$color-mood-green: #2ca02c;
$color-mood-yellow: #f6d513;
$color-mood-orange: #f90;
$color-mood-grey: #c6c6c6;
$color-mood-pink: #f25e5a;
$color-mood-red: #d62728;

// Link Colors
$base-link-color: $color-blue-clear;
$hover-link-color: darken($color-blue-clear, 5);

// OLD COLORS
$color-grey: #ccc;
$color-grey-medium: #777;

// Fonts
// ------------------------------------
$base-font-family: 'Roboto', sans-serif;
$header-font-family: 'Roboto', sans-serif;

$base-font-color: $color-grey-indifferent;

// Font Sizes
$base-font-size: 1em !default;
$h1-font-size: $base-font-size * 2.25 !default;
$h2-font-size: $base-font-size * 2 !default;
$h3-font-size: $base-font-size * 1.5 !default;
$h4-font-size: $base-font-size * 1.375 !default;
$h5-font-size: $base-font-size * 0.875 !default;
$h6-font-size: $base-font-size * 0.75 !default;

// Line height
$base-line-height: 1.5 !default;
$header-line-height: 1.25 !default;
$base-spacing: $base-line-height * 1em !default;

// Easing Curves
// ------------------------------------
$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
$swift-ease-in-out: all $swift-ease-in-out-duration
  $swift-ease-in-out-timing-function;

// z-index's
// ------------------------------------
$z-index-image-modal: 130 !default;
$z-index-loading: 110 !default;
$z-index-tooltip: 100 !default;
$z-index-toast: 90 !default;
$z-index-dialog: 80 !default;
$z-index-tip: 70 !default;
$z-index-sidenav: 60 !default;
$z-index-backdrop: 50 !default;
$z-index-dropdown: 30 !default;
$z-index-fab: 20 !default;
$z-index-header: 10 !default;
$z-index-background: -1 !default;
