.channel-provider-info {
  display: flex;
  align-items: center;

  &__main {
    flex: 1;
    padding-right: 24px;
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    margin: 8px 0;
  }

  &__link {
    font-size: 12px;
    font-weight: 600;

    g-icon {
      vertical-align: middle;
    }
  }

  &__logo {
    width: 160px;
  }
}
