.channel-list-item-dashboard {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  &__item {
    flex: 1;
    min-height: 85px;
    border-radius: 4px;
    border: 1px solid rgba($color-black, 0.05);
    margin: 0 0 10px;
    padding: 10px;
    display: flex;

    &.-wide {
      width: 100%;
    }
  }

  &__item-main {
    font-size: 14px;
    max-width: 184px; // HACK: stop growing due to long links
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item-title {
    font-size: 14px;
  }

  &__item-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 50px;
    }
  }

  &__item-stats {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      text-align: center;
    }
  }

  &__links {
    width: 100%;
  }

  &__panel {
    flex: 1;
    padding: 8px;
  }

  &__quotas {
    flex: 1;
    padding: 8px;

    &-item {
      margin: 0 0 16px;
    }
  }
}
